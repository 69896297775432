var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-toolbar',{attrs:{"color":"primary","dark":"","dense":""}},[_c('v-toolbar-title',{attrs:{"dense":""}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.undo()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("History.undo")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.redo()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("History.redo")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.bullet_list()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-list-bulleted")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("BulletList.bulletList")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.heading({level: 1})}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("H1")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Heading.heading.level_1")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.heading({level: 2})}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("H2")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Heading.heading.level_2")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.heading({level: 3})}}},'v-btn',attrs,false),on),[_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v("H3")])])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Heading.heading.level_3")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.underline()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-underline")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Underline.underline")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.bold()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-bold")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Bold.bold")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.italic()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-italic")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Italic.italic")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.$emit('select-attachment')}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"rotate-6"},[_vm._v("mdi-attachment")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("messagerie.pj.open_dialog")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.blockquote()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-quote-open")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Blockquote.blockquote")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.strike()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-strikethrough-variant")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Strike.strike")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.code()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-code-tags")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Code.code")))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-2",attrs:{"small":"","icon":""},on:{"click":function($event){return _vm.commands.hard_break()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-format-line-spacing")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.getLabel("Paragraph.paragraph")))])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }