<template>
  <div>
    <v-alert
      class="warning-alert-by-state" 
      v-if="isFicheDone"
      variant="outlined"
      type="warning"
      prominent
    >
      {{ $t('notifications.update_status_file')  }}
    </v-alert>
    <tiptap-vuetify
      :extensions="extensionsTipTap"
      :placeholder="placeholder"
      @input="inputMessage"
      :value="value"
      v-if="!isFicheDone"
    >
      <template v-slot:toolbar="{ commands, isActive }">
        <tiptap-toolbar
          :commands="commands"
          :isActive="isActive"
          @select-attachment="$emit('select-attachment')"
        >
        </tiptap-toolbar>
      </template>
    </tiptap-vuetify>
  </div>
</template>

<script>
import UtilUnit from '@/Utils/Units';
import { TiptapVuetify } from 'tiptap-vuetify';
import {
  History,
  Heading,
  Bold,
  Italic,
  Paragraph,
  ListItem,
  BulletList,
  Image,
  Underline,
  Blockquote,
  Strike,
  Code,
  HardBreak,
} from 'tiptap-vuetify';

import TiptapToolbar from '@/Components/Commons/Inputs/Editor/EditorToolbar';
import Vuex from 'vuex';

export default {
  name: 'Editor',
  components: {
    TiptapToolbar,
    TiptapVuetify,
  },
  props: {
    value: String,
    placeholder: String,
  },
  computed: {
    ...Vuex.mapGetters(['getMessagerieData']),

    users() {
      return this.getMessagerieData().users;
    },
    isFicheDone() {
      return this.$store.state.isStatusFicheDone;
    },
  },
  data() {
    return {
      items: [],
      mention: '',
      content: ``,
      extensionsTipTap: [
        History,
        ListItem,
        BulletList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Italic,
        Image,
        Paragraph,
        Underline,
        Blockquote,
        Strike,
        Code,
        HardBreak,
      ],
    };
  },
  methods: {
    /**
     * Fonction qui s'enclenche lors de la saisie dans l'input
     */
    inputMessage(content) {
      let wrapper = document.createElement('div');
      wrapper.innerHTML = content;
      //Si on a des images dans le content, on vérifie si elles ne font pas plus de 2mo
      if (wrapper.getElementsByTagName('img').length > 0) {
        Object.entries(wrapper.getElementsByTagName('img')).map(
          async (i, index) => {
            const img = i[1].src;
            const buffer = Buffer.from(img.substring(img.indexOf(',') + 1));
            let calc = (buffer.length * 25) / 100;
            if (buffer.length - calc > 2e6) {
              //Refresh le contenu
              this.$emit('fail-file');
              this.$nError(
                this.$t('validation.maxFileSizeX', {
                  val: UtilUnit.readableFileSize(2e6, true),
                })
              );
              i[1].parentNode.removeChild(i[1]);
            }
          }
        );
      }
      this.$emit('input', wrapper.innerHTML);
    },
  },
};
</script>

<style>
.warning-alert-by-state {
  margin: 0;
}
</style>
