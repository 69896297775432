<template>
  <v-toolbar color="primary" dark dense>

    <v-toolbar-title dense>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.undo()"
          >
            <v-icon>mdi-undo</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("History.undo") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.redo()"
          >
            <v-icon>mdi-redo</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("History.redo") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.bullet_list()"
          >
            <v-icon>mdi-format-list-bulleted</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("BulletList.bulletList") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.heading({level: 1})"
          >
            <span style="font-weight: bold;">H1</span>
          </v-btn>
        </template>
        <span>{{ getLabel("Heading.heading.level_1") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.heading({level: 2})"
          >
            <span style="font-weight: bold;">H2</span>
          </v-btn>
        </template>
        <span>{{ getLabel("Heading.heading.level_2") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.heading({level: 3})"
          >
            <span style="font-weight: bold;">H3</span>
          </v-btn>
        </template>
        <span>{{ getLabel("Heading.heading.level_3") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.underline()"
          >
            <v-icon>mdi-format-underline</v-icon>  
          </v-btn>
        </template>
        <span>{{ getLabel("Underline.underline") }}</span> 
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.bold()"
          >
            <v-icon>mdi-format-bold</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Bold.bold") }}</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.italic()"
          >
            <v-icon>mdi-format-italic</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Italic.italic") }}</span>
      </v-tooltip>

<!--      <v-tooltip top>-->
<!--        <template v-slot:activator="{ on, attrs }">-->
<!--          <v-btn-->
<!--              v-bind="attrs"-->
<!--              v-on="on"-->
<!--              small-->
<!--              icon-->
<!--              class="mx-2"-->
<!--              @click="commands.image({src: windowUrl})"-->
<!--          >-->
<!--            <v-icon>mdi-image</v-icon>-->
<!--          </v-btn>-->
<!--        </template>-->
<!--        <span>{{ getLabel("Image") }}</span>-->
<!--      </v-tooltip>-->

      <!-- <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.paragraph()"
          >
            <v-icon>mdi-format-paragraph</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Paragraph.paragraph") }}</span>
      </v-tooltip> -->

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="$emit('select-attachment')"
          >
            <v-icon class="rotate-6">mdi-attachment</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("messagerie.pj.open_dialog") }}</span>
      </v-tooltip>

      <!-- blockquote -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.blockquote()"
          >
            <v-icon>mdi-format-quote-open</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Blockquote.blockquote") }}</span>
      </v-tooltip>

      <!-- strike -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.strike()"
          >
            <v-icon>mdi-format-strikethrough-variant</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Strike.strike") }}</span>
      </v-tooltip>

      <!-- code -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.code()"
          >
            <v-icon>mdi-code-tags</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Code.code") }}</span>
      </v-tooltip>

      <!-- hard break -->
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
              v-bind="attrs"
              v-on="on"
              small
              icon
              class="mx-2"
              @click="commands.hard_break()"
          >
            <v-icon>mdi-format-line-spacing</v-icon>
          </v-btn>
        </template>
        <span>{{ getLabel("Paragraph.paragraph") }}</span>
      </v-tooltip>

    </v-toolbar-title>
  </v-toolbar>
</template>

<script>
import Vuex from "vuex"

export default {
  name: "EditorToolbar",
  props: {
    isActive: Object,
    commands: Object
  },
  computed: {
    ...Vuex.mapState(["preferences"]),
    lang() {
      return this.$vuetify.lang.current
    }
  },
  data() {
    return {
      langFile: null,
    }
  },
  methods: {
    getLabel(extension) {

      // Test d'abord si le fichier de langue existe
      if (this.langFile) {

        // Split aux points pour un traitement plus facile
        extension = extension.split(".")

        // Si l'extension est bien référencée dans le fichier de traduction
        if (this.langFile.default.extensions[extension[0]]) {
          let tooltipLabel = this.langFile.default.extensions[extension[0]].buttons

          if (tooltipLabel) {

            // La dimension tooltip existe parfois et parfois non, récupère donc la bonne dimension pour ensuite
            if (tooltipLabel.tooltip) {
              tooltipLabel = tooltipLabel.tooltip
            } else {
              tooltipLabel = tooltipLabel[extension[1]].tooltip
            }

            // Si l'element trouvée est une string, on la retourne, si non on l'appelle comme une fonction
            if (typeof tooltipLabel == "string") {
              return tooltipLabel
            } else {
              let args = extension[2].split("_")
              return tooltipLabel({[args[0]]: args[1]})
            }
          }
        }
      }
      return extension
    }
  },
  created() {

    // Récupère le fichier de traduction original de tiptap-vuetify en fonction de la langue courante
    import(`tiptap-vuetify/src/i18n/${this.lang}/index.ts`).then(file => {
      this.langFile = file
    })
  }
}
</script>

<style scoped>

</style>